<template>
  <page-content>

    <div class="row">

      <a-card>
      <common-table
          ref="table"
          path="web/device-project"
          :columns="columns"
          @onLoadData="onGetProjectList"
      >

        <template slot="search">
          <a-button ghost type="primary" @click="add">新增项目</a-button>
        </template>

        <template slot="operation" slot-scope="{record}">
          <action-view @click="view(record)"></action-view>
          <action-edit @click="edit(record)"></action-edit>
          <action-delete @click="remove(record)"></action-delete>
        </template>
      </common-table>
      </a-card>


      <a-card v-if="selectedProject.id>0" class="flex-fill" style="margin-left: 20px" :title="selectedProject.name">

        <div class="project-info">

          <div class="item">
            <div class="top"></div>
            <div class="content">所属分公司: {{selectedProject.companyName}}</div>
            <div class="bottom"></div>
          </div>

          <div class="item">
            <div class="top"></div>
            <div class="content">所属地区: {{selectedProject.areaName}}</div>
            <div class="bottom"></div>
          </div>

          <div class="item">
            <div class="top"></div>
            <div class="content">地址: {{selectedProject.address}}</div>
            <div class="bottom"></div>
          </div>
        </div>


        <a-tabs>
          <a-tab-pane key="1" tab="设备管理">
          </a-tab-pane>
          <a-space slot="tabBarExtraContent">
            <a-button>导入</a-button>
            <a-button>导出</a-button>
            <a-button>模板下载</a-button>
          </a-space>
        </a-tabs>

        <common-table
            ref="deviceTable"
            path="web/iot/device"
            :columns="deviceColumns"
            :query-params="queryParams"
        >

          <template slot="search">
            <a-button ghost type="primary" @click="addDevice">添加设备</a-button>
          </template>

          <template slot="status" slot-scope="{record}">
            <a-tag v-if="!record.online" color="#D1D1D1">离线</a-tag>
            <a-tag v-else-if="record.alarm" color="#EEC900">报警</a-tag>
            <a-tag v-else color="#00ff99">在线</a-tag>
          </template>

          <template slot="operation" slot-scope="{record}">
            <action-edit @click="editDevice(record)"></action-edit>
            <action-delete @click="removeDevice(record)"></action-delete>
          </template>
        </common-table>

      </a-card>

    </div>

    <ProjectEdit ref="editDrawer" @success="getList"></ProjectEdit>
    <DeviceEdit ref="deviceEdit" @success="getDeviceList"></DeviceEdit>

  </page-content>
</template>

<script>

import ProjectEdit from './ProjectEdit'
import DeviceEdit from "../../device/DeviceEdit"

export default {
  components: {
    ProjectEdit,DeviceEdit
  },
  data() {
    return {
      queryParams:{
        projectId:""
      },
      selectedProject:{}
    }
  },

  computed: {
    columns() {
      return [
        {
          title: '项目名称',
          dataIndex: 'name'
        },
        {
          title: '地区',
          dataIndex: 'areaName'
        },
        {
          title: '分公司',
          dataIndex: 'companyName'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    },
    deviceColumns(){
      return [
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: {customRender: 'status'},
        },
        {
          title: '设备名称',
          dataIndex: 'nickname'
        },
        {
          title: '设备编号',
          dataIndex: 'iotId'
        },
        {
          title: '设备型号',
          dataIndex: 'model'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: {customRender: 'operation'}
        }
      ]
    }
  },
  methods: {
    add() {
      this.$refs.editDrawer.show()
    },
    view(record){
      this.selectedProject = record
      this.queryParams.projectId = record.id
      this.getDeviceList()
    },
    edit(record) {
      this.$refs.editDrawer.show(record)
    },
    remove(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/device-company/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getList()
          })
        }
      })
    },
    getList() {
      this.$refs.table.getData()
    },

    addDevice() {
      this.$refs.deviceEdit.show({projectId:this.selectedProject.id})
    },
    editDevice(record) {
      this.$refs.deviceEdit.show(record)
    },
    removeDevice(record) {
      let that = this
      this.$confirm({
        title: '是否删除该记录?',
        content: '该操作不可撤销',
        centered: true,
        onOk() {
          that.$delete('web/iot/device/' + record.id).then(() => {
            that.$message.success('删除成功')
            that.getDeviceList()
          })
        }
      })
    },

    getDeviceList(){
      this.$refs.deviceTable.getData()
    },
    onGetProjectList(list){
      if(!this.selectedProject.id && list.length>0){
        this.view(list[0])
      }
    }
  }
}
</script>

<style lang="less" scoped>
.row{
  display: flex;
}

.project-info{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 10px;
  margin-bottom: 20px;

  .item{
    .top{
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      height: 5px;
    }
    .content{
      padding: 0 10px;
    }
    .bottom{
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      height: 5px;
    }
  }
}

</style>
