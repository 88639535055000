<template>
  <common-form
      ref="form"
      url="web/device-project"
      :items="items"
      :edit="edit"
      :beforeRequest="beforeRequest"
      @success="$emit('success')">
  </common-form>
</template>
<script>

export default {
  data() {
    return {
      edit: false,
      companyTreeData: [],
      areaTreeData: []
    }
  },
  computed: {
    items() {
      return [
        {
          type: "input",
          name: "名称",
          key: "name",
          required: true,
        },
        {
          type: "tree-select",
          name: "所属分公司",
          key: "companyId",
          treeData: this.companyTreeData
        },
        {
          type: "tree-select",
          name: "所属地区",
          key: "areaId",
          treeData: this.areaTreeData
        },
        {
          type: "location",
          name: "地址",
          key: "location",
        },
      ]
    }
  },
  methods: {
    show(model = {}) {
      this.edit = model.id > 0
      if(this.edit){
        model.location = {...model}
      }
      this.$refs.form.show(model)

      this.$get('web/device-company').then((r) => {
        this.companyTreeData = r.data
      })

      this.$get('web/device-area').then((r) => {
        this.areaTreeData = r.data
      })
    },
    beforeRequest(model){
      return {
        ...model,
        ...model.location
      }
    }
  }
}
</script>
